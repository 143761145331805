<template>
  <div class="banner"></div>
</template>

<script>
export default {
  name: "BottomBanner",
};
</script>

<style lang="scss" scoped>
.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  background-color: #000000;
  background-image: url("../assets/images/bottom-banner.svg");
  background-size: 100% auto;
  background-repeat: repeat-x;
  background-position: left center;
  z-index: 1501;

  @media screen and (max-width: 991.98px) {
    background-image: url("../assets/images/bottom-banner-992.jpg");
  }

  @media screen and (max-width: 575.98px) {
    background-image: url("../assets/images/bottom-banner-mobile.jpg");
  }
}
</style>
