import {
  gsap,
  TweenMax,
  TimelineMax,
  CSSPlugin,
  Sine,
  Linear
} from "gsap";

gsap.registerPlugin(CSSPlugin)
// import { Tween } from "jquery";
//  Power2, Elastic, CSSPlugin ,Tweenmax
export function playLightAnimation(targetClass = '') {
  let t = new TimelineMax({
    repeat: -1,
    yoyo: true,
    delay: 2 * Math.random()
  })
  let light_paths = `.${targetClass} [data-name='light'] path`
  let light = `.${targetClass} [data-name='light']`

  t.staggerTo(light_paths, 0.1, {
    css: {
      opacity: "1",
    }
  }, 0.25)

  t.staggerTo(light, 0.2, {
    css: {
      opacity: "0",
    }
  }, 0.5)

  t.staggerTo(light, 0.2, {
    css: {
      opacity: "1",
    }
  }, 0.5)

}